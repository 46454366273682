<template>
    <div class="w-full vx-row md:w-2/3 lg:w-1/2">
        <vx-card>
        <div class="mt-3 vx-row">
            <header class="w-full vx-col sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                <div>
                    <h2 >{{ url.key }}</h2>
                    <p>{{ $t("urlform.description") }}</p>
                </div>
            </header>
        </div>

         <div v-for="url in urls" :key="url.lang">
            <LabelForm>{{ getLabelLanguage(url.language) }}</LabelForm>
            <vs-input
              class="w-full border-grey-light"
              v-model="url.url"
              :danger="!isValidUrl(url.url)"
              danger-text="Invalid URL"
            />
        </div>

        <LabelForm >{{ $t("urlform.label.published") }}</LabelForm>
        <vs-select class="border-grey-light xs:w-full sm:w-1/3" v-model="published">
            <vs-select-item :key="option.value" :value="option.value" :text="option.label" v-for="option in this.publishedOptions"/>
        </vs-select>
        <div class="mt-10">
            <vs-button color="primary" :disabled="!isValidForm" type="filled rounded-lg w-48 mr-10" @click="saveUrl()">{{ $t("urlform.label.save") }}</vs-button>
            <vs-button type="border" class="w-48 rounded-lg" @click="goToUrlListRoute()">{{ $t("urlform.label.cancel") }}</vs-button>
        </div>
        </vx-card>
    </div>
</template>
<script>

import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import LanguageService from '@/modules/Shared/Services/LanguageService'
import UrlsServices from '@/modules/Shared/Services/UrlsServices.js'
import { getPublishedOptions } from '@/modules/Resorts/Helpers/urlHelper.js'
import store from "@/modules/Shared/Store/store";

export default {
    name: 'edit-url',
    components: {
        LabelForm
    },
    data() {
        return {
            published: '',
            urls: [],
            languages: [],
            publishedOptions: [],
            url: null
        }
    },
    async beforeRouteEnter (to, from, next) {
        const url = await UrlsServices.getUrl(to.params.urlUuid)
        await store.dispatch('setRouteElement', url)
        next(vm => vm.url = url)
    },
    async created() {
        this.languages = await LanguageService.getLanguages()

        this.published = this.url.published
        this.urls = this.url.urls.map(urlLanguage => {
           const language = this.findLanguageByAcronim(urlLanguage.lang)
           return {
             ...urlLanguage,
             language: language
           }
        });

        this.publishedOptions = getPublishedOptions()
    },

    computed: {
      isValidForm() {
        return !this.urls
          .map(urlLanguage => urlLanguage.url)
          .some(urlLanguage => !this.isValidUrl(urlLanguage))
      }
    },

    methods: {
        async saveUrl() {
            try {
              await UrlsServices.updateUrl(this.url.uuid, {
                published: this.published,
                urls: this.urls.map(urlLanguage => ({
                  lang: urlLanguage.lang,
                  url: urlLanguage.url
                }))
              })
              this.$vs.notify( { text: this.$t("urlform.updated.ok"), color: 'success', position:'top-right' } )
              setTimeout(() => {
                this.goToUrlListRoute()
              }, 1000)
            } catch (error) {
              this.$vs.notify( { text: this.$t("urlform.updated.ko"), color: 'danger', position:'top-right'})
            }
        },

        getLabelLanguage(language) {
          const mainLanguage = language.acronym === this.url.main_lang ? `(${this.$t('urlform.primarylanguage')})` : ''
          return `URL ${language.name} ${mainLanguage}`
        },

        isValidUrl(url) {
          return UrlsServices.isValidUrl(url)
        },

        findLanguageByAcronim(acronym) {
          return this.languages.find(language => language.acronym === acronym)
        },

        goToUrlListRoute() {
          const urlListRoute = this.$route.path.replace('/' + this.$route.params.urlUuid, '')
          this.$router.push(urlListRoute)
        }
    }
}
</script>
