var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full vx-row md:w-2/3 lg:w-1/2" },
    [
      _c(
        "vx-card",
        [
          _c("LabelForm", [_vm._v(_vm._s(_vm.$t("urlform.key")))]),
          _c("vs-input", {
            staticClass: "w-full border-grey-light",
            attrs: {
              danger: _vm.key.dirty && _vm.key.value === "",
              "danger-text": "Invalid Key"
            },
            on: {
              focusout: function($event) {
                _vm.key.dirty = true
              },
              input: function($event) {
                _vm.key.dirty = true
              }
            },
            model: {
              value: _vm.key.value,
              callback: function($$v) {
                _vm.$set(_vm.key, "value", $$v)
              },
              expression: "key.value"
            }
          }),
          _vm._l(this.languages, function(language) {
            return _c(
              "div",
              { key: language.acronym },
              [
                _c("LabelForm", [
                  _vm._v(_vm._s(_vm.getLabelLanguage(language)))
                ]),
                _c("vs-input", {
                  staticClass: "w-full border-grey-light",
                  attrs: {
                    danger:
                      language.url.dirty && !_vm.isValidUrl(language.url.url),
                    "danger-text": "Invalid URL"
                  },
                  on: {
                    focusout: function($event) {
                      language.url.dirty = true
                    },
                    input: function($event) {
                      language.url.dirty = true
                    }
                  },
                  model: {
                    value: language.url.url,
                    callback: function($$v) {
                      _vm.$set(language.url, "url", $$v)
                    },
                    expression: "language.url.url"
                  }
                })
              ],
              1
            )
          }),
          _c("LabelForm", [_vm._v(_vm._s(_vm.$t("urlform.label.published")))]),
          _c(
            "vs-select",
            {
              staticClass: "border-grey-light xs:w-full sm:w-1/3",
              model: {
                value: _vm.published,
                callback: function($$v) {
                  _vm.published = $$v
                },
                expression: "published"
              }
            },
            _vm._l(this.publishedOptions, function(option) {
              return _c("vs-select-item", {
                key: option.value,
                attrs: { value: option.value, text: option.label }
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "mt-10" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.isValidForm,
                    type: "filled rounded-lg w-48 mr-10"
                  },
                  on: {
                    click: function($event) {
                      return _vm.createUrl()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("urlform.label.save")))]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "w-48 rounded-lg",
                  attrs: { type: "border" },
                  on: {
                    click: function($event) {
                      return _vm.goToUrlListRoute()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("urlform.label.cancel")))]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }