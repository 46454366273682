import axios from '@/axios.js'
export default {
  getUrls: async (parameters) => {
    try {
      const response = await axios.editorapi.get(`v4/urls`, { params: parameters })
      return response.data.object
    } catch (error) {
      throw new Error(error)
    }
  },

  getUrl: async (urlUuid) => {
    try {
        const response = await axios.editorapi.get(`v4/urls/${urlUuid}`)
        return response.data.object
    } catch (error) {
        throw new Error(error)
    }
  },

  updateUrl: async (urlUuid, payload) => {
    try {
        const response = await axios.editorapi.patch(`v4/urls/${urlUuid}`, payload)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
  },

  createUrl: async (urlUuid, payload) => {
    try {
      const response = await axios.editorapi.post(`v4/urls/${urlUuid}`, payload)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  deleteUrl: async (urlUuid) => {
    try {
      const response = await axios.editorapi.delete(`v4/urls/${urlUuid}`)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  isValidUrl(url) {
    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(url);
  },
}


