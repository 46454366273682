<template>
  <div class="w-full vx-row md:w-2/3 lg:w-1/2">
    <vx-card>

      <LabelForm>{{ $t('urlform.key') }}</LabelForm>
      <vs-input
        class="w-full border-grey-light"
        v-model="key.value"
        @focusout="key.dirty = true"
        @input="key.dirty = true"
        :danger="key.dirty && key.value === ''"
        danger-text="Invalid Key"
      />

      <div v-for="language in this.languages" :key="language.acronym">
        <LabelForm>{{ getLabelLanguage(language) }}</LabelForm>
        <vs-input
          class="w-full border-grey-light"
          v-model="language.url.url"
          @focusout="language.url.dirty = true"
          @input="language.url.dirty = true"
          :danger="language.url.dirty && !isValidUrl(language.url.url)"
          danger-text="Invalid URL"
        />
      </div>

      <LabelForm >{{ $t("urlform.label.published") }}</LabelForm>
      <vs-select class="border-grey-light xs:w-full sm:w-1/3" v-model="published">
        <vs-select-item :key="option.value" :value="option.value" :text="option.label" v-for="option in this.publishedOptions"/>
      </vs-select>
      <div class="mt-10">
        <vs-button color="primary" :disabled="!isValidForm" type="filled rounded-lg w-48 mr-10" @click="createUrl()">{{ $t("urlform.label.save") }}</vs-button>
        <vs-button type="border" class="w-48 rounded-lg" @click="goToUrlListRoute()">{{ $t("urlform.label.cancel") }}</vs-button>
      </div>
    </vx-card>
  </div>
</template>
<script>

import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import UrlsServices from '@/modules/Shared/Services/UrlsServices.js'
import { getPublishedOptions } from '@/modules/Resorts/Helpers/urlHelper.js'
import AppsService from "@/modules/Apps/Services/AppsService";
import { v4 as uuidv4 } from 'uuid';
import sha256 from 'crypto-js/sha256'
import PaginationService from "@/modules/Shared/Services/PaginationService";

export default {
  name: 'create-url',
  components: {
    LabelForm
  },
  data() {
    return {
      key: {
        value: '',
        dirty: false
      },
      published: 0,
      urls: [],
      languages: [],
      publishedOptions: [],
    }
  },

  async created() {
    this.languages = (await AppsService.getLanguages(this.$route.params.uuid))
      .map(language => ({...language, url: { url: '', dirty: false }}))

    this.publishedOptions = getPublishedOptions()
  },

  computed: {
    isValidForm() {
      const someInvalidUrl = this.languages
        .map(language => language.url.url)
        .some(url => !this.isValidUrl(url))

      const invalidKey = this.key.value === ''

      return !invalidKey && !someInvalidUrl
    }
  },

  methods: {
      async isValidKey(key) {
          const parameters = {
              filters: [
                  { field: 'application_uuid', value: this.$route.params.uuid },
                  { field: 'key', value: key }
              ]
          }
          const urls = (await UrlsServices.getUrls(PaginationService.parseParameters(parameters))).data

          return urls.length === 0
      },
    async createUrl() {
      if (!await this.isValidKey(this.key.value)) {
        this.$vs.notify( { text: `Key ${this.key.value} already exists`, color: 'danger', position:'top-right'})
        return
      }

      const payload = {
        app_uuid: this.$route.params.uuid,
        key: this.key.value,
        main_lang: this.languages.find(language => language.is_default).acronym,
        is_apps_fallback: false,
        is_resorts_fallback: false,
        published: this.published,
        urls: this.languages.map(language => ({
          lang: language.acronym,
          url: language.url.url
        }))
      }

      try {
        await UrlsServices.createUrl(sha256(uuidv4()), payload)
        this.$vs.notify( { text: this.$t("urlform.created.ok"), color: 'success', position:'top-right' } )
        setTimeout(() => {
          this.goToUrlListRoute()
        }, 1000)
      } catch (error) {
        this.$vs.notify( { text: this.$t("urlform.created.ko"), color: 'danger', position:'top-right'})
      }
    },

    isValidUrl(url) {
      return UrlsServices.isValidUrl(url)
    },

    getLabelLanguage(language) {
      const mainLanguage = language.is_default ? `(${this.$t('urlform.primarylanguage')})` : ''
      return `URL ${language.name} ${mainLanguage}`
    },

    goToUrlListRoute() {
      const urlListRoute = this.$route.path.replace('/new', '')
      this.$router.push(urlListRoute)
    },
  }
}
</script>
