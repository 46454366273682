<template>
  <DatatablePageLayout>

    <template v-slot:actionButtons>
      <vs-button v-if="context === 'app'" class="xs:w-full sm:w-auto rounded-lg" @click="goToCreatePage()">
        {{ $t('urlslist.create_button') }}
      </vs-button>
    </template>

    <template v-slot:datatable>
      <Datatable
        :config="datatableConfig"
        :elements="urlsTable"
        :stripe="true"
        :draggable="false"
        :sst="true"
        @action="handleAction"
        @checkbox="publish"
        @filter="filter"
        :pagination="pagination"
      />
    </template>

  </DatatablePageLayout>
</template>

<script>

import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import UrlsServices from '@/modules/Shared/Services/UrlsServices.js'
import { getPublishedOptions } from '@/modules/Resorts/Helpers/urlHelper.js'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout";

export default {
    name: 'urls',
    components: {
        DatatablePageLayout,
        Datatable
    },
    data() {
        return {
            contextUuid: null,
            urls: {},
            urlsTable: [],
            datatableConfig: [
                { 'field' : 'key', head : this.$t("urlslist.table.key"), 'searchable' : true, 'sortable' : true },
                { 'field' : 'published', 'type': 'check', head : this.$t("urlslist.table.publish"), 'searchable' : true, 'searchableoptions':getPublishedOptions(), 'options':getPublishedOptions(), 'sortable' : true },
                { 'field' : 'actions', 'type': 'actions', head : this.$t("urlslist.table.actions"), 'searchable' : false, 'sortable' : false }
            ],
            pagination: {},
            baseParameters: {
              filters: []
            }
        }
    },
    props:{
      context: {
        type: String,
        required: true
      }
    },
    async created() {
        this.contextUuid = this.$route.params.uuid
        if (this.context === 'resort') {
          this.baseParameters.filters = [
            { field: 'resort_uuid', value: this.contextUuid }
          ]
        } else {
          this.baseParameters.filters = [
            { field: 'application_uuid', value: this.contextUuid }
          ]
        }

        await this.getUrls(PaginationService.parseParameters(this.baseParameters));
    },
    methods: {
        async publish(uuid, value) {
            this.urls = UrlsServices.updateUrl(uuid, { published: value ? 1 : 0 })
        },

        async getUrls(parameters) {
            this.urls = await UrlsServices.getUrls(parameters);

            this.pagination = {
                per_page: this.urls.per_page,
                total: this.urls.total
            }

            this.getMappedUrls()
        },

        getMappedUrls() {
            this.urlsTable = this.urls.data.map( url => ({
                uuid:url.uuid,
                key:url.key,
                published:url.published,
                'actions' : this.context === 'app'
                  ? [ 'edit', 'delete' ]
                  : [ 'edit' ]
            }))
        },

        filter(parameters) {
            this.getUrls(PaginationService.parseParameters({
              ...parameters,
              filters: [
                ...parameters.filters,
                ...this.baseParameters.filters
              ]}
            ))
        },

        handleAction(type, uuid) {
            switch(type) {
                case 'edit':
                    this.$router.push(this.$route.path + '/' + uuid)
                    break;
                case 'delete':
                    this.deleteUrl(uuid)
                    break;
            }
        },

        goToCreatePage() {
          const createUrlRoute = this.$route.path + '/new'
          this.$router.push(createUrlRoute)
        },

        async deleteUrl(urlUuid) {
          try {
            await UrlsServices.deleteUrl(urlUuid)
            this.$vs.notify( { text: this.$t("urlform.deleted.ok"), color: 'success', position:'top-right' } )
            this.urlsTable = this.urlsTable.filter(url => url.uuid !== urlUuid)
          } catch (error) {
            this.$vs.notify( { text: this.$t("urlform.deleted.ko"), color: 'danger', position:'top-right'})
          }
        }
    },
}
</script>
