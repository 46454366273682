var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _vm.context === "app"
              ? _c(
                  "vs-button",
                  {
                    staticClass: "xs:w-full sm:w-auto rounded-lg",
                    on: {
                      click: function($event) {
                        return _vm.goToCreatePage()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("urlslist.create_button")) + " ")]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _c("Datatable", {
              attrs: {
                config: _vm.datatableConfig,
                elements: _vm.urlsTable,
                stripe: true,
                draggable: false,
                sst: true,
                pagination: _vm.pagination
              },
              on: {
                action: _vm.handleAction,
                checkbox: _vm.publish,
                filter: _vm.filter
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }